<template>
  <div>
       <div class="tool">
           <div >
               <label class="title">上报时间：</label>
               <el-date-picker v-model="searchForm.time" type="daterange" range-separator="至" value-format="yyyy-MM-dd" format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
           </div>
            <el-input v-model="searchForm.name" placeholder="点位名称或故障单号" class="a" style="width:200px;"></el-input>
           <div class="a">
               <label class="title">状态：</label>
                <el-select v-model="searchForm.status" placeholder="请选择">
                     <el-option label="全部" :value="0"></el-option>
                     <el-option label="新故障单" :value="1"></el-option>
                     <el-option label="已安排维修" :value="2"></el-option>
                     <el-option label="已维修" :value="3"></el-option>
                </el-select>
           </div>
           <el-button type="primary" icon="el-icon-search" class="a" @click="Search"></el-button>
        </div>

        <el-table :data='DataList' stripe :highlight-current-row='true'  @expand-change="expandChanged" max-height='calc(100vh - 205px)' height='calc(100vh - 205px)'>
             <el-table-column type="expand">
                 <template slot-scope="slot">
                     <div>
                        <el-image style="width: 150px; height: 150px;vertical-align:middle;margin-left:15px"  v-for='(item,index) in slot.row.picdata' :key="index" :src='item.photo' fit="fill" @click="ImgClick(slot.row,index)"></el-image>
                        <span style="margin-left:30px;font-size:15px">故障描述：{{slot.row.order_detail}}</span>
                        <el-dialog title="故障详细图" :visible.sync="imgDialogVisible" :append-to-body='true'>
                          <img width="100%" :src="imgSrc" alt="">
                        </el-dialog>
                     </div>
                 </template>
             </el-table-column>
            <el-table-column align="center" prop="order_no" label="单号"></el-table-column>
            <el-table-column align="center" prop="order_time" label="上报时间">
                <template slot-scope="slot">
                    {{FormateData(slot.row.order_time,2)}}
                </template>
            </el-table-column>
            <el-table-column align="center" prop="media_name" label="点位名称"></el-table-column>
            <el-table-column align="center" prop="media_community_addr" label="地址"></el-table-column>
            <el-table-column align="center" prop="order_report_user" label="上报人员"></el-table-column>
            <el-table-column align="center" prop="order_phone" label="联系方式"></el-table-column>
            <el-table-column align="center" prop="order_detail" label="故障描述"></el-table-column>
            <el-table-column align="center" prop="order_status" label="状态">
                <template slot-scope="slot">
                    {{slot.row.order_status===0?'新故障单':(slot.row.order_status===1?'已安排维修':'已维修')}}
                </template>
            </el-table-column>

        </el-table>
        <el-pagination class="fy" layout="prev, pager, next" @current-change="current_change" :total="searchForm.total" background :page-size="searchForm.pageSize"></el-pagination>
  </div>
</template>

<script>
import util from '../../util/util'

export default {
  data: function () {
    return {
      DataList: [],
      searchForm: {
        time: [],
        status: 0,
        name: '',
        pageNo: 1,
        pageSize: 18,
        total: 0
      },
      imgDialogVisible: false,
      imgSrc: ''
    }
  },
  computed: {
    FormateData () {
      return function (time, type) {
        return util.FormateDate(time, type)
      }
    }
  },
  mounted () {
    this.GetDataList()
  },
  methods: {
    GetDataList () {
      this.searchForm.begintime = this.searchForm.time[0]
      this.searchForm.endtime = this.searchForm.time[1]// 直接传递数组，后台接收不到
      util.Get('faultorder/getfaultorderdatalist', this.searchForm).then(res => {
        if (res.rpStatus === 10000) {
          console.log(res.list)
          if (res.list.length > 0) {
            res.list.forEach(element => {
              element.picdata = ''
            })
          }
          this.DataList = res.list
          this.searchForm.total = res.total
        }
      })
    },
    Search () {
      this.GetDataList()
    },
    current_change (page) {
      this.searchForm.pageNo = page
      this.GetDataList()
    },
    expandChanged (row, expandedRows) {
      var arr = expandedRows.filter(a => a.order_id === row.order_id)
      if (arr.length > 0) {
        // 展开行
        if (row.picdata === '' && row.order_photo !== null && row.order_photo !== undefined && row.order_photo !== '') {
          util.Get('picture/getpicture', { state_type1: 3, pic_name1: row.order_photo }).then(res => {
            if (res.rpStatus === 10000) {
              row.picdata = res.list[0]
            }
          })
        }
      }
    },
    ImgClick (row, index) {
      this.imgSrc = ''
      this.imgDialogVisible = true
      if (row.picdata !== null && row.picdata.length > 0) { this.imgSrc = row.picdata[index].photo }
    }
  }
}
</script>

<style scoped>
.content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.tool{
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-items: center;
}

.title{
    font-size: 13px;

}
.a{
    margin-left: 20px;
}
.el-table{
    margin-top: 15px;
}
.el-link{
    margin-right: 10px;
    font-size: 10px;
}
</style>
